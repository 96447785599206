const LINE_COLOR = "orange";
const LINE_WIDTH =2;
const MIN_SCORE = 0;
const TENSION = 0

export {
    LINE_COLOR,
    LINE_WIDTH,
    MIN_SCORE,
    TENSION
}